import { graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"

import DesktopNavigation from "@root/DesktopNavigation"
import MobileNavigation from "@root/MobileNavigation"
import React from "react"
import { useBreakpoint } from "@hooks/useBreakpoint"
import { useTemplate } from "@context/TemplateContext"

const TopBarContainer = styled.div`
  ${tw`fixed w-full h-0 `}
  z-index: 100;
`

const NavBar = () => {
  const data = useStaticQuery(graphql`
    query MainMenue {
      wpMenu(slug: { eq: "main-menu" }) {
        id
        menuItems {
          nodes {
            id
            path
            label
            parentId
            childItems {
              nodes {
                id
                label
                path
                parentId
                childItems {
                  nodes {
                    id
                    label
                    path
                    parentId
                  }
                }
              }
            }
          }
        }
        slug
      }
    }
  `)
  const { isMobile } = useBreakpoint()
  const templateName = useTemplate()

  // const menuItems = data.wpMenu.nodes[0]?.menuItems?.nodes
  const menuItems = data.wpMenu.menuItems.nodes.filter(menuItem => menuItem.parentId === null)
  // console.log('MenuItems:', menuItems);
  if (!menuItems) return null
  return (
    <TopBarContainer>
      {typeof window !== "undefined" && isMobile && <MobileNavigation pageTemplate={templateName} menuItems={menuItems} />}
      {typeof window !== "undefined" && !isMobile && <DesktopNavigation pageTemplate={templateName} menuItems={menuItems} />}
    </TopBarContainer>
  )
}

export default NavBar
