import tw, { styled } from "twin.macro"
import React from "react"
import ScrollTop from "@elements/ScrollTop"
import LogoSrc from "../../images/logo-schmitts-katze.svg"
import { Link } from "gatsby"

const FooterContainer = styled.footer`
  ${tw`flex flex-col items-center pt-20 pb-24 bg-black `}
`
const FooterMenu = styled.div`
  ${tw`flex flex-col w-full mb-12 px-skOffset md:mb-24`}
`
const Logo = styled.img`
  ${tw`w-5/12 py-20 mx-auto md:w-3/12`}
`

const Footer = () => {
  return (
    <FooterContainer>
      <FooterMenu>
        <Link to={`/kontakt/`} className="font-black uppercase text-yellow text-20 lg:text-24 2xl:text-32">
          Kontakt
        </Link>
        <Link to={`/datenschutz/`} className="font-black uppercase text-yellow text-20 lg:text-24 2xl:text-32">
          Datenschutz
        </Link>
        <Link to={`/impressum/`} className="font-black uppercase text-yellow text-20 lg:text-24 2xl:text-32">
          Impressum
        </Link>
        <Link to={`/blog/`} className="font-black uppercase text-yellow text-20 lg:text-24 2xl:text-32">
          Blog
        </Link>
      </FooterMenu>
      <Logo src={LogoSrc} alt="Logo Schmitts Katze" />
      <ScrollTop />
    </FooterContainer>
  )
}

export default Footer
