import React from "react"
import tw, { styled } from "twin.macro"
import { CookieBanner } from "@palmabit/react-cookie-law"

const Container = styled.div`
  ${tw`fixed bottom-0 left-0 z-[102] w-full `}
`
const Overlay = styled.div`
  ${tw`fixed inset-0 z-[101] w-full bg-black/80`}
`

const CookieContainer = () => {
  const [consentGiven, setConsentGiven] = React.useState(false)

  return (
    <>
      {!consentGiven && <Overlay />}
      <Container>
        <CookieBanner
          styles={{
            dialog: {
              bottom: "0px",
              backgroundColor: "#00FF00",
              position: "absolute",
              width: "100%",
            },
            container: {
              maxWidth: "960px",
              marginLeft: "auto",
              marginRight: "auto",
              padding: "1rem .4rem",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            },
            message: {
              fontSize: ".85rem",
              color: "#000",
            },
            policy: {
              fontSize: ".85rem",
              color: "#000",
              textDecoration: "underline",
              // marginLeft: ".3rem",
            },
            selectPane: {
              width: "100%",
              padding: ".4rem 0",
            },
            optionLabel: {
              fontSize: ".85rem",
              color: "#000",
              paddingLeft: "1.5rem",
            },
            buttonWrapper: {
              marginLeft: "auto",
              marginTop: ".5rem",
              marginBottom: ".5rem",
              width: "100%",
            },
            button: {
              backgroundColor: "#E6FF00",
              text: "#000",
              textTransform: "uppercase",
              padding: ".5rem 1rem",
              fontFamily: "Raleway, serif",
              fontSize: ".7rem",
              margin: "0 .4rem 0 0",
              boxShadow: "3px 3px 1px #000",
            },
          }}
          privacyPolicyLinkText="Datenschutz"
          policyLink="/datenschutz"
          message="Hallo! Könnten wir bitte einige zusätzliche Dienste für Analytics aktivieren? Sie können Ihre Zustimmung später jederzeit ändern oder zurückziehen."
          wholeDomain={true}
          acceptButtonText="Los geht's!"
          managePreferencesButtonText="Lassen Sie mich wählen"
          necessaryOptionText="Notwendige"
          statisticsOptionText="Analytics"
          showMarketingOption={false}
          showPreferencesOption={false}
          savePreferencesButtonText="Auswahl speichern"
          onAccept={() => {
            setConsentGiven(true)
          }}
          onAcceptPreferences={() => {}}
          onAcceptStatistics={() => {}}
          onAcceptMarketing={() => {}}
        />
      </Container>
    </>
  )
}

export default CookieContainer
