import "./src/styles/globals.css"
import Layout from "@root/Layout"
import React from "react"
import smoothscroll from 'smoothscroll-polyfill';
import { TemplateProvider } from "@context/TemplateContext";

const isBrowser = () => typeof window !== 'undefined'
isBrowser() && smoothscroll.polyfill();

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  let TRANSITION_DELAY = 500
  if (location.action === "PUSH") {
    let locationState = location.state?.noInitialAni || null
    
    window.setTimeout(() => window.scroll({
      left: 0,
      top: 0
  }), locationState !== null ? 0 : 500 )
  }
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }
  return false
}

export const wrapPageElement = ({ element, props }) => {
  const templateName = props.pageContext?.template || "";

  return (
    <TemplateProvider templateName={templateName}>
      <Layout {...props}>{element}</Layout>
    </TemplateProvider>
  );
}