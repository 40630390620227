exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-cases-jsx": () => import("./../../../src/pages/cases.jsx" /* webpackChunkName: "component---src-pages-cases-jsx" */),
  "component---src-pages-glossar-jsx": () => import("./../../../src/pages/glossar.jsx" /* webpackChunkName: "component---src-pages-glossar-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-default-page-template-jsx": () => import("./../../../src/templates/DefaultPageTemplate.jsx" /* webpackChunkName: "component---src-templates-default-page-template-jsx" */),
  "component---src-templates-single-case-jsx": () => import("./../../../src/templates/SingleCase.jsx" /* webpackChunkName: "component---src-templates-single-case-jsx" */),
  "component---src-templates-single-glossar-jsx": () => import("./../../../src/templates/SingleGlossar.jsx" /* webpackChunkName: "component---src-templates-single-glossar-jsx" */)
}

