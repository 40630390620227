import React, { useState } from 'react'
import tw, { styled } from "twin.macro"

const ScrollTopContainer = styled.div`
    ${tw`
        fixed
        bg-black
        lg:bottom-12
        lg:right-12
        sm:bottom-6
        sm:right-6
        bottom-0
        right-0
        z-50
        cursor-pointer
        items-center
        justify-center
    `}
`

const ScrollTop = () =>{
    const [showScrollTop, setShowScrollTop] = useState(false)

    const handleScrollTopPosition = () => {
        if (!showScrollTop && window.pageYOffset > 800){
            setShowScrollTop(true)
        } else if (showScrollTop && window.pageYOffset <= 800){
            setShowScrollTop(false)
        }
    }

    const scrollTop = () =>{
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        })
    }

    typeof window !== 'undefined' && window.addEventListener('scroll', handleScrollTopPosition)

    return (
        <ScrollTopContainer className="scrollTop" onClick={scrollTop} style={{height: 50, width: 50, display: showScrollTop ? 'flex' : 'none'}}>
            <svg id="scrollTopSvg" width="40" height="40" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#fff" fill="none">				
                <path height="24" width="24" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="#fff" d="M 4.704 14.554 L 12 8.443 L 19 14.85 M 4.704 14.853 L 12 8.149 L 19 14.853">
                    <animate
                        calcMode="linear"
                        keyTimes="0;1"
                        begin="scrollTopSvg.mouseenter"
                        fill="freeze"
                        attributeName="d" dur="0.3s"
                        values="M 4.704 14.554 L 12 8.443 L 19 14.85 M 4.704 14.853 L 12 8.149 L 19 14.853;
                        M5 11l7-7 7 7M5 19l7-7 7 7" />
                    <animate
                        calcMode="linear"
                        keyTimes="0;1"
                        begin="scrollTopSvg.mouseleave"
                        fill="freeze"
                        attributeName="d" dur="0.3s"
                        values="M5 11l7-7 7 7M5 19l7-7 7 7;
                        M 4.704 14.554 L 12 8.443 L 19 14.85 M 4.704 14.853 L 12 8.149 L 19 14.853" />
                </path>
            </svg>
        </ScrollTopContainer>
    )
}

export default ScrollTop;