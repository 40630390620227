import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { motion } from "framer-motion";
import { useStateContext } from "@context/stateContext"

const Button = (buttonData) => {
    const stateContext = useStateContext()
    const buttonStyle = buttonData.buttonStyle,
        buttonClasses = buttonData.buttonClasses,
        buttonText = buttonData.buttonText,
        buttonHref = buttonData.buttonHref

        const contactPopUpOpen = () => {
            stateContext.dispatch({ action: "SET_CONTACT_POP_UP", data: true })
            document.body.style.overflow = 'hidden'
          }

    return (
        <motion.div initial={{transformOrigin: "50% 0%", scale: 1}} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} className="flex justify-center">
            {buttonData.buttonHref === '/kontakt/' ? 
            <button onClick={contactPopUpOpen} style={ buttonStyle } className={buttonClasses}>{buttonText}</button> :
            <Link onClick={buttonData.onClick} to={buttonHref} style={ buttonStyle } className={buttonClasses}>
                {buttonText}
            </Link>            
            }
        </motion.div>
    )
}

Button.propTypes = {
  buttonData: PropTypes.object,
}

export default Button
