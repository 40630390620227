import { useEffect, useState } from 'react'

import debounce from 'lodash-es/debounce'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'

const Tailwind = resolveConfig(tailwindConfig)
const isBrowser = () => typeof window !== 'undefined'

export const useBreakpoint = () => {
    const breakpointLG = parseInt(Tailwind.theme.screens.lg)
    const breakpointMD = parseInt(Tailwind.theme.screens.md)
    const breakpointSM = parseInt(Tailwind.theme.screens.sm)

    // OLD: const [isMobile, setIsMobile] = useState(isBrowser() && window.innerWidth < breakpointLG ? true : false )
    const [isMobile, setIsMobile] = useState( isBrowser() && window.matchMedia(`(max-width: ${breakpointLG - 1 }px)`).matches )

    // OLD: const [isScreenLg, setIsScreenLg] = useState(isBrowser() && window.innerWidth < breakpointLG ? true : false )
    const [isScreenLg, setIsScreenLg] = useState( isBrowser() && window.matchMedia(`(max-width: ${breakpointLG - 1 }px)`).matches && window.matchMedia(`(min-width: ${breakpointMD}px)`).matches )
    
    // OLD: const [isScreenMd, setIsScreenMd] = useState( (isBrowser() && window.innerWidth < breakpointMD) && (isBrowser() && window.innerWidth > breakpointSM) ? true : false )
    const [isScreenMd, setIsScreenMd] = useState( isBrowser() && window.matchMedia(`(max-width: ${breakpointMD - 1}px)`).matches && window.matchMedia(`(min-width: ${breakpointSM}px)`).matches )
    
    // OLD: const [isScreenSm, setIsScreenSm] = useState( isBrowser() && window.innerWidth < breakpointSM ? true : false )
    const [isScreenSm, setIsScreenSm] = useState( isBrowser() && window.matchMedia(`(max-width: ${breakpointSM - 1}px)`).matches )
    const setBreakpoints = () => {
        setIsMobile(isBrowser() && window.matchMedia(`(max-width: ${breakpointLG - 1 }px)`).matches)
        setIsScreenLg(isBrowser() && window.matchMedia(`(max-width: ${breakpointLG - 1 }px)`).matches && window.matchMedia(`(min-width: ${breakpointMD}px)`).matches) //lg
        setIsScreenMd(isBrowser() && window.matchMedia(`(max-width: ${breakpointMD - 1}px)`).matches && window.matchMedia(`(min-width: ${breakpointSM}px)`).matches) //md
        setIsScreenSm(isBrowser() && window.matchMedia(`(max-width: ${breakpointSM - 1}px)`).matches) //sm && sm-

        
    }
    const onWindowResize = debounce(setBreakpoints, 250)
    useEffect(() => {
        isBrowser() && window.addEventListener('resize', onWindowResize)
        return () => {isBrowser() && window.removeEventListener('resize', onWindowResize)}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return {
        isMobile,
        isScreenLg,
        isScreenMd,
        isScreenSm
    }
}
